import merge from 'lodash/fp/merge';
import set from 'lodash/fp/set';

import {
  B2BContractType, BenefitGroupType,
  ClientContractField,
  CompanyField,
  CompanyType,
  ContractField,
  ContractStatus,
  DeductibleCostsType,
  EmploymentContractType,
  FilterContractType,
  IdType,
  ProgressionLevel,
  Settlement,
  SortCompany,
  UserField,
  BenefitBuyerType,
  OnCallMultiplier,
  PaymentStatus,
  CalendarDayType,
  AbsenceType,
  BenefitBillingPeriod,
  BenefitInvoiceCategory,
  ContractType,
  AdditionalPeopleNumber,
  ContractDocumentTemplateLanguage,
  Language,
  Quarter,
  BonusType,
  AbsenceTypeInput,
  VatNotAppliedReason,
  HolidayCalendarId,
  CompanyRole,
} from '@virtuslab/nfs-shared/src/schema/admin';
import { parsePluralMessageBlocks, parsePluralMessages } from '@virtuslab/nfs-shared/src/services/i18n/messages';

import {
  IsStudent,
} from '../../additionalTypes';
import { PendingStateDescription } from '../../salaries';

import messages, { enumMessage, pluralBuildingBlocks, pluralMessages } from '../messages';

import type { DefaultLanguage } from '.';

const stringToObject = <T extends string>(arg: T) => set<Record<T, T>>(arg, arg, {});

const translations: DefaultLanguage = {
  ...messages.map(stringToObject).reduce(merge),
  ...parsePluralMessages(pluralMessages),
  ...parsePluralMessageBlocks(pluralBuildingBlocks),
  [enumMessage('userField', UserField.ANNUALLEAVEEXTENDEDLIMITSINCE)]: 'Annual leave extension date',
  [enumMessage('userField', UserField.ANNUALLEAVESTATUS)]: 'Annual leave status',
  [enumMessage('userField', UserField.WORKPLACE)]: 'Workplace',
  [enumMessage('userField', UserField.FIRSTNAME)]: 'First name',
  [enumMessage('userField', UserField.LASTNAME)]: 'Last name',
  [enumMessage('userField', UserField.CONTACTEMAIL)]: 'Contact email',
  [enumMessage('userField', UserField.PHONENUMBER)]: 'Phone number',
  [enumMessage('userField', UserField.ADDRESS)]: 'Address',
  [enumMessage('userField', UserField.MAILINGADDRESS)]: 'Mailing address',
  [enumMessage('userField', UserField.IDTYPE)]: 'ID document',
  [enumMessage('userField', UserField.IDNUMBER)]: 'ID number',
  [enumMessage('userField', UserField.PESEL)]: 'Pesel',
  [enumMessage('userField', UserField.TIN)]: 'TIN',
  [enumMessage('userField', UserField.BIRTHDATE)]: 'Birth date',
  [enumMessage('userField', UserField.ISSTUDENT)]: 'Student under-26 years old',
  [enumMessage('userField', UserField.ISSTUDENTREVISIONDATE)]: 'Revision date',
  [enumMessage('userField', UserField.CITIZENSHIP)]: 'Citizenship',
  [enumMessage('userField', UserField.PROGRESSIONLEVEL)]: 'Progression level',
  [enumMessage('userField', UserField.AVATAR)]: 'Avatar',
  [enumMessage('userField', UserField.SPACEID)]: 'Space',
  [enumMessage('userField', UserField.CONTRACTS)]: 'Contracts',
  [enumMessage('userField', UserField.MIDDLENAME)]: 'Middle name',
  [enumMessage('userField', UserField.NEXTMEDICALEXAMINATIONDATE)]: 'Next medical examination',
  [enumMessage('userField', UserField.REVENUEADMINISTRATIONOFFICE)]: 'Revenue administration office',
  [enumMessage('userField', UserField.FAMILYNAME)]: 'Family name',
  [enumMessage('deductibleCostType', DeductibleCostsType.COPYRIGHT)]: 'Copyright',
  [enumMessage('deductibleCostType', DeductibleCostsType.NORMAL)]: '20%',
  [enumMessage('deductibleCostType', DeductibleCostsType.RAISED)]: '50%',
  [enumMessage('contractField', ContractField.TERMINATIONDATE)]: 'Termination date',
  [enumMessage('contractField', ContractField.COPYRIGHTCOSTS)]: 'Copyright cost',
  [enumMessage('contractField', ContractField.ONCALL)]: 'On-call rates',
  [enumMessage('contractField', ContractField.TAXDEDUCTIBLECOSTSTYPE)]: 'Tax deductible costs',
  [enumMessage('contractField', ContractField.PPK)]: 'PPK',
  [enumMessage('contractField', ContractField.WORKINGTIME)]: 'Working Time',
  [enumMessage('contractField', ContractField.OVERTIMERATE)]: 'Overtime rate',
  [enumMessage('contractField', ContractField.RATEAMOUNT)]: 'Rate',
  [enumMessage('contractField', ContractField.STATUS)]: 'Status',
  [enumMessage('contractField', ContractField.EXPECTEDENDDATE)]: 'End date',
  [enumMessage('contractField', ContractField.SCOPEOFWORK)]: 'Scope of work',
  [enumMessage('contractField', ContractField.POSITION)]: 'Position',
  [enumMessage('contractField', ContractField.PESEL)]: 'PESEL',
  [enumMessage('contractField', ContractField.NOTES)]: 'Note',
  [enumMessage('contractField', ContractField.ASSIGNEDVARIANTS)]: 'Assigned benefit variants',
  [enumMessage('contractField', ContractField.MAILINGADDRESS)]: 'Mailing address',
  [enumMessage('contractField', ContractField.ADDRESS)]: 'Address',
  [enumMessage('contractField', ContractField.INITIALANNUALLEAVE)]: 'Initial annual leave',
  [enumMessage('contractField', ContractField.OVERWRITTENPROMISORTRANSFERBANKACCOUNTNUMBER)]: 'Overwritten promisor transfer bank account',
  [enumMessage('contractField', ContractField.OVERWRITTENACTIVEREPORTINGRANGE)]: 'Overwritten active reporting range',
  [enumMessage('contractField', ContractField.PRESELECTEDFROMTIME)]: 'Preselected from time',
  [enumMessage('contractField', ContractField.PRESELECTEDPROJECTUSERID)]: 'Preselected project user id',
  [enumMessage('contractField', ContractField.PRESELECTEDTOTIME)]: 'Preselected to time',
  [enumMessage('contractField', ContractField.PRESELECTEDWORKTAGID)]: 'Preselected work tag id',
  [enumMessage('contractField', ContractField.BONUSES)]: 'Bonuses',
  [enumMessage('contractField', ContractField.PROMISEETRANSFERBANKACCOUNTNUMBER)]: 'Promisor transfer bank account',
  [enumMessage('companyField', CompanyField.BANKACCOUNTS)]: 'Bank account',
  [enumMessage('companyField', CompanyField.DEFAULTBANKACCOUNTNUMBER)]: 'Default bank account',
  [enumMessage('companyField', CompanyField.EUVATTYPE)]: 'EU vat payer status',
  [enumMessage('companyField', CompanyField.ISPOLISHVAT)]: 'Polish Vat payer status',
  [enumMessage('companyField', CompanyField.ISOTHERVATTYPE)]: 'Other Vat payer status',
  [enumMessage('companyField', CompanyField.ADDRESS)]: 'Address',
  [enumMessage('companyField', CompanyField.COMPANYTYPE)]: 'Company type',
  [enumMessage('companyField', CompanyField.MAILINGADDRESS)]: 'Mailing address',
  [enumMessage('companyField', CompanyField.NAME)]: 'Name',
  [enumMessage('companyField', CompanyField.REGISTERID)]: 'Register ID',
  [enumMessage('companyField', CompanyField.REGON)]: 'Regon',
  [enumMessage('companyField', CompanyField.TAXNUMBER)]: 'Tax number',
  [enumMessage('clientContractField', ClientContractField.CONDITIONS)]: 'Terms and conditions',
  [enumMessage('clientContractField', ClientContractField.CONTRACTDURATIONFROM)]: 'Start date',
  [enumMessage('clientContractField', ClientContractField.CONTRACTDURATIONTO)]: 'End date',
  [enumMessage('clientContractField', ClientContractField.CONTRACTRENEWAL)]: 'Renewal date',
  [enumMessage('clientContractField', ClientContractField.CURRENCY)]: 'Currency',
  [enumMessage('clientContractField', ClientContractField.NOTES)]: 'Notes',
  [enumMessage('clientContractField', ClientContractField.ONCALL)]: 'On call rates',
  [enumMessage('clientContractField', ClientContractField.PAYMENTDEADLINE)]: 'Payment deadline',
  [enumMessage('clientContractField', ClientContractField.POSITIONS)]: 'Position',
  [enumMessage('clientContractField', ClientContractField.TERMSOFPAYMENT)]: 'Terms of payment',
  [enumMessage('clientContractField', ClientContractField.VAT)]: 'Vat rate',
  [enumMessage('clientContractField', ClientContractField.NAME)]: 'Name',
  [enumMessage('clientContractField', ClientContractField.ISFACTORINGSUPPORTED)]: 'Is factoring supported',
  [enumMessage('clientContractField', ClientContractField.RATESRENEWALDATE)]: 'Rates renewal date',
  [enumMessage('employmentContractType', EmploymentContractType.UOP)]: 'UOP',
  [enumMessage('employmentContractType', EmploymentContractType.UOD)]: 'UOD',
  [enumMessage('employmentContractType', EmploymentContractType.UZ)]: 'UZ',
  [enumMessage('employmentContractType', EmploymentContractType.MANAGEMENT)]: 'Management',
  [enumMessage('settlement', Settlement.DAILY)]: 'Daily',
  [enumMessage('settlement', Settlement.HOURLY)]: 'Hourly',
  [enumMessage('settlement', Settlement.MONTHLY)]: 'Monthly',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEERINTERN)]: 'Intern',
  [enumMessage('progressionLevel', ProgressionLevel.NONENGINEERINTERN)]: 'Intern',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEERJUNIOR)]: 'Junior',
  [enumMessage('progressionLevel', ProgressionLevel.NONENGINEERJUNIOR)]: 'Junior',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEERREGULAR)]: 'Regular',
  [enumMessage('progressionLevel', ProgressionLevel.NONENGINEERREGULAR)]: 'Regular',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEERSENIOR)]: 'Senior',
  [enumMessage('progressionLevel', ProgressionLevel.NONENGINEERSENIOR)]: 'Senior',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEEREXPERT)]: 'Expert',
  [enumMessage('progressionLevel', ProgressionLevel.NONENGINEEREXPERT)]: 'Expert',
  [enumMessage('progressionLevel', ProgressionLevel.ENGINEERPRINCIPAL)]: 'Principal',
  [enumMessage('contractStatus', ContractStatus.CONCLUDED)]: 'Signed',
  [enumMessage('contractStatus', ContractStatus.TERMINATED)]: 'Terminated',
  [enumMessage('contractStatus', ContractStatus.ACTIVE)]: 'Active',
  [enumMessage('idType', IdType.IDCARD)]: 'ID card',
  [enumMessage('idType', IdType.PASSPORT)]: 'Passport',
  [enumMessage('companyType', CompanyType.CLIENT)]: 'Client',
  [enumMessage('companyType', CompanyType.GROUP)]: 'Group',
  [enumMessage('companyType', CompanyType.B2B)]: 'B2B',
  [enumMessage('companyType', CompanyType.SUPPLIER)]: 'Supplier',
  [enumMessage('companyType', CompanyType.CAPITALGROUP)]: 'Capital group',
  [enumMessage('sortCompany', SortCompany.REGISTERID)]: 'Register Id',
  [enumMessage('sortCompany', SortCompany.REGON)]: 'Regon',
  [enumMessage('sortCompany', SortCompany.TAXNUMBER)]: 'Tax Number',
  [enumMessage('sortCompany', SortCompany.NAME)]: 'Name',
  [enumMessage('sortCompany', SortCompany.CREATIONDATE)]: 'Create date',
  [enumMessage('b2bContractType', B2BContractType.B2B)]: 'B2B',
  [enumMessage('b2bContractType', B2BContractType.SUBCONTRACT)]: 'Sub',
  [enumMessage('filterContractType', FilterContractType.B2B)]: 'B2B',
  [enumMessage('filterContractType', FilterContractType.SUBCONTRACT)]: 'Subcontract',
  [enumMessage('filterContractType', FilterContractType.MANAGEMENT)]: 'Management',
  [enumMessage('filterContractType', FilterContractType.UOP)]: 'UOP',
  [enumMessage('filterContractType', FilterContractType.UOD)]: 'UOD',
  [enumMessage('filterContractType', FilterContractType.UZ)]: 'UZ',
  [enumMessage('isStudent', IsStudent.True)]: 'Yes',
  [enumMessage('isStudent', IsStudent.NotApplicable)]: 'No or not applicable',
  [enumMessage('benefitGroupType', BenefitGroupType.HEALTH)]: 'Health',
  [enumMessage('benefitGroupType', BenefitGroupType.OFFICE)]: 'Office',
  [enumMessage('benefitGroupType', BenefitGroupType.SPORT)]: 'Sport',
  [enumMessage('benefitBuyerType', BenefitBuyerType.PROMISEE)]: 'Promisee',
  [enumMessage('benefitBuyerType', BenefitBuyerType.PROMISOR)]: 'Promisor',
  [enumMessage('benefitBuyerType', BenefitBuyerType.THIRDPARTY)]: '3rd party',
  [enumMessage('contractDocumentTemplateLanguage', ContractDocumentTemplateLanguage.EN)]: 'English',
  [enumMessage('contractDocumentTemplateLanguage', ContractDocumentTemplateLanguage.PL)]: 'Polish',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.ONE)]: '1',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.TWO)]: '2',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.THREE)]: '3',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.FOUR)]: '4',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.MORE)]: 'More than 4',
  [enumMessage('pendingStateDescription', PendingStateDescription.ACCOUNTINGDATAMISSING)]: 'Missing payroll details',
  [enumMessage('pendingStateDescription', PendingStateDescription.INVOICEMISSING)]: 'Invoice missing',
  [enumMessage('pendingStateDescription', PendingStateDescription.INVOICEUNCHECKED)]: 'Invoice unverified',
  [enumMessage('pendingStateDescription', PendingStateDescription.TIMEREPORTINVALID)]: 'Time report invalid',
  [enumMessage('pendingStateDescription', PendingStateDescription.WHITELISTINVALID)]: 'Not verified on whitelist',
  [enumMessage('pendingStateDescription', PendingStateDescription.NOBANKACCOUNT)]: 'No bank account',
  [enumMessage('pendingStateDescription', PendingStateDescription.ACCOUNTINGDATAMISSING)]: 'Missing payroll details',
  [enumMessage('pendingStateDescription', PendingStateDescription.INVOICEFILEMISSING)]: 'Missing invoice file',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.INVOICEMISSING)]: 'Invoice(s) missing',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.INVOICEUNCHECKED)]: 'Invoice(s) unverified',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.TIMEREPORTINVALID)]: 'Time report(s) invalid',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.WHITELISTINVALID)]: 'Not verified on whitelist',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.NOBANKACCOUNT)]: 'No bank account(s)',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.ACCOUNTINGDATAMISSING)]: 'Missing payroll details',
  [enumMessage('pendingStateDescriptionPlural', PendingStateDescription.INVOICEFILEMISSING)]: 'Missing invoice file(s)',
  [enumMessage('onCallMultiplier', OnCallMultiplier.ONE)]: 'x1',
  [enumMessage('onCallMultiplier', OnCallMultiplier.ONEANDHALF)]: 'x1.5',
  [enumMessage('onCallMultiplier', OnCallMultiplier.THREE)]: 'x3',
  [enumMessage('paymentStatus', PaymentStatus.DONE)]: 'Done',
  [enumMessage('paymentStatus', PaymentStatus.PENDING)]: 'Pending',
  [enumMessage('paymentStatus', PaymentStatus.READY)]: 'Ready',
  [enumMessage('calendarDayType', CalendarDayType.HOLIDAY)]: 'Public holiday',
  [enumMessage('calendarDayType', CalendarDayType.FREEDAYFOROTHERDAY)]: 'Virtuslab holiday',
  [enumMessage('absenceType', AbsenceType.SICKLEAVE)]: 'Sick leave',
  [enumMessage('absenceType', AbsenceType.OUTOFOFFICE)]: 'Out of office',
  [enumMessage('absenceType', AbsenceType.ANNUALLEAVE)]: 'Annual leave',
  [enumMessage('absenceType', AbsenceType.UNPAIDLEAVE)]: 'Unpaid leave',
  [enumMessage('absenceType', AbsenceType.SPECIALLEAVE)]: 'Special leave',
  [enumMessage('absenceType', AbsenceType.FLEXTIMELEAVE)]: 'Flextime leave',
  [enumMessage('absenceType', AbsenceType.MATERNITYLEAVE)]: 'Maternity leave',
  [enumMessage('absenceType', AbsenceType.PAIDLEAVE)]: 'Paid leave',
  [enumMessage('absenceType', AbsenceType.DUETOFORCEMAJEURELEAVE)]: 'Unexpected event leave',
  [enumMessage('absenceType', AbsenceType.CHILDCARELEAVE)]: 'Child care leave',
  [enumMessage('absenceType', AbsenceType.CAREGIVINGLEAVE)]: 'Caregiving leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.SICKLEAVE)]: 'Sick leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.OUTOFOFFICE)]: 'Out of office',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.ANNUALLEAVE)]: 'Annual leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.UNPAIDLEAVE)]: 'Unpaid leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.SPECIALLEAVE)]: 'Special leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.FLEXTIMELEAVE)]: 'Flextime leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.MATERNITYLEAVE)]: 'Maternity leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.PAIDLEAVE)]: 'Paid leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.DUETOFORCEMAJEURELEAVE)]: 'Unexpected event leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.CHILDCARELEAVE)]: 'Child care leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.CAREGIVINGLEAVE)]: 'Caregiving leave',
  [enumMessage('benefitBillingPeriod', BenefitBillingPeriod.HOURLY)]: 'Hourly',
  [enumMessage('benefitBillingPeriod', BenefitBillingPeriod.MONTHLY)]: 'Monthly',
  [enumMessage('benefitInvoiceCategory', BenefitInvoiceCategory.BUYERSBENEFIT)]: 'Buyer\'s benefit',
  [enumMessage('benefitInvoiceCategory', BenefitInvoiceCategory.BUYERSCOST)]: 'Buyer\'s cost',
  [enumMessage('contractType', ContractType.B2B)]: 'B2B',
  [enumMessage('contractType', ContractType.SUBCONTRACT)]: 'Subcontract',
  [enumMessage('contractType', ContractType.MANAGEMENT)]: 'Management',
  [enumMessage('contractType', ContractType.UOP)]: 'UOP',
  [enumMessage('contractType', ContractType.UOD)]: 'UOD',
  [enumMessage('contractType', ContractType.UZ)]: 'UZ',
  [enumMessage('bonusType', BonusType.APPRECIATION)]: 'Appreciation bonus',
  [enumMessage('bonusType', BonusType.EMPLOYEEREFERRAL)]: 'Employee referral',
  [enumMessage('bonusType', BonusType.LANGUAGELEARNING)]: 'Language learning',
  [enumMessage('bonusType', BonusType.OTHER)]: 'Other',
  [enumMessage('bonusType', BonusType.TRAININGPACKAGE)]: 'Training package',
  [enumMessage('bonusType', BonusType.REMOTEWORKEQUIPMENT)]: 'Remote work equipment',
  [enumMessage('language', Language.ENGLISH)]: 'English',
  [enumMessage('language', Language.OTHER)]: 'Other',
  [enumMessage('quarter', Quarter.Q1)]: 'Q1',
  [enumMessage('quarter', Quarter.Q2)]: 'Q2',
  [enumMessage('quarter', Quarter.Q3)]: 'Q3',
  [enumMessage('quarter', Quarter.Q4)]: 'Q4',
  [enumMessage('vatNotAppliedReason', VatNotAppliedReason.EXEMPTFROMVAT)]: 'Exempt from VAT',
  [enumMessage('vatNotAppliedReason', VatNotAppliedReason.VATNOTAPPLICABLE)]: 'VAT not applicable',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.PL)]: 'Poland',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.SE)]: 'Sweden',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.DE_BW)]: 'Germany - Bavaria',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.DE_HE)]: 'Germany - Hessen',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.CH_ZURYCH)]: 'Switzerland - Zürich',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.UK_ENG)]: 'United kingdom - England',
  [enumMessage('companyRole', CompanyRole.CHIEFOFFICER)]: 'C-level',
  [enumMessage('companyRole', CompanyRole.DIRECTOR)]: 'Director',
  [enumMessage('companyRole', CompanyRole.HEAD)]: 'Head of',
  [enumMessage('companyRole', CompanyRole.MANAGER)]: 'Manager',
  [enumMessage('companyRole', CompanyRole.LEAD)]: 'Lead',
  [enumMessage('companyRole', CompanyRole.EMPLOYEE)]: 'Employee',
} as const;

export default translations;
