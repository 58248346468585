import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';
import { withRedirect } from '@virtuslab/nfs-shared/src/services/routes';

import { PersonDetailsRoutes } from '../../../config/paths';

const Profile = lazy(async () => import('../../organisms/Profile'));
const Progression = lazy(async () => import('../../organisms/UserProgression'));
const ContractsAndBenefits = lazy(async () => import('../../organisms/ContractsAndBenefits'));
const UserDocuments = lazy(async () => import('../../organisms/UserDocuments'));
const FriendsAndFamily = lazy(async () => import('../../organisms/FriendsAndFamily'));
const ProjectsAndActivities = lazy(async () => import('../../organisms/ProjectsAndActivities'));
const UserTimeReports = lazy(async () => import('../../organisms/UserTimeReports'));
const AnnualLeaveDetails = lazy(async () => import('../../organisms/AnnualLeaveDetails'));
const PersonDetails = lazy(async () => import('.'));

const config: RoutesConfig<WrapRoutes<typeof PersonDetailsRoutes>> = {
  paths: {
    [PersonDetailsRoutes.DETAILS]: {
      render: withRedirect(PersonDetails, {
        from: PersonDetailsRoutes.DETAILS,
        to: PersonDetailsRoutes.PROFILE,
      }),
    },
    [PersonDetailsRoutes.PROFILE]: {
      render: Profile,
    },
    [PersonDetailsRoutes.CONTRACTS_AND_BENEFITS__NO_OPTIONALS]: {
      render: ContractsAndBenefits,
    },
    [PersonDetailsRoutes.CONTRACTS_AND_BENEFITS]: {
      render: ContractsAndBenefits,
    },
    [PersonDetailsRoutes.PROGRESSION__NO_OPTIONALS]: {
      render: Progression,
    },
    [PersonDetailsRoutes.PROGRESSION]: {
      render: Progression,
    },
    [PersonDetailsRoutes.DOCUMENTS]: {
      render: UserDocuments,
    },
    [PersonDetailsRoutes.ANNUAL_LEAVE__NO_OPTIONALS]: {
      render: AnnualLeaveDetails,
    },
    [PersonDetailsRoutes.ANNUAL_LEAVE]: {
      render: AnnualLeaveDetails,
    },
    [PersonDetailsRoutes.ANNUAL_LEAVE_ENTITLEMENT]: {
      render: AnnualLeaveDetails,
    },
    [PersonDetailsRoutes.ANNUAL_LEAVE_SUMMARY]: {
      render: AnnualLeaveDetails,
    },
    [PersonDetailsRoutes.FRIENDS_AND_FAMILY]: {
      render: FriendsAndFamily,
    },
    [PersonDetailsRoutes.PROJECTS_AND_ACTIVITIES]: {
      render: ProjectsAndActivities,
    },
    [PersonDetailsRoutes.TIME_REPORTS_CONTRACT]: {
      render: UserTimeReports,
    },
    [PersonDetailsRoutes.TIME_REPORTS_CONTRACT_WITH_MONTH]: {
      render: UserTimeReports,
      reusePath: PersonDetailsRoutes.TIME_REPORTS_CONTRACT,
    },
    [PersonDetailsRoutes.TIME_REPORTS_CONTRACT_WITH_DAY]: {
      render: UserTimeReports,
      reusePath: PersonDetailsRoutes.TIME_REPORTS_CONTRACT,
    },
    [PersonDetailsRoutes.TIME_REPORTS_CONTRACT_WITH_ENTRY]: {
      render: UserTimeReports,
      reusePath: PersonDetailsRoutes.TIME_REPORTS_CONTRACT,
    },
  },
};

export default config;
