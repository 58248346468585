import { gql } from '@apollo/client';

// language=GraphQL
export default gql(`
  directive @client on FIELD

  interface Highlight {
    id: ID
  }

  type TimeBasedHighlight implements Highlight {
    id: ID
    effectiveDate: Date!
  }

  #TODO: #405
  interface AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type AddClientContractDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredCompanyId: CompanyId!
    requiredCompanyType: [CompanyType!]!
  }

  type YearlyCostSummaryDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredUserId: UserId!
  }

  type EditClientContractDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredClientContractId: ClientContractId!
    requiredCompanyType: [CompanyType!]!
    requiredCompanyId: CompanyId!
  }

  type RateCardDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    editing: Boolean!
    requiredClientContractId: ClientContractId!
    addNewPosition: Boolean
    highlight: TimeBasedHighlight
    effectiveDate: Date
  }

  type AddCompanyDrawerInitialData {
    name: String
    taxNumber: String
    companyType: CompanyType
  }

  type AddCompanyDrawerReturnData {
    id: String!
    name: String!
    taxNumber: String
  }

  type AddCompanyDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    returnData: AddCompanyDrawerReturnData
    initialData: AddCompanyDrawerInitialData
  }

  type B2BContractDraftDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    editing: Boolean!
    requiredUserId: UserId!
    errors: [String!]!
  }

  type SubcontractDraftDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    editing: Boolean!
    requiredUserId: UserId!
    errors: [String!]!
  }

  type CompanyBankAccountDrawer implements  AbstractDrawer {
    id: ID!
    child: AbstractDrawer,
    visible: Boolean!,
    editing: Boolean!,
    requiredCompanyTypes: [CompanyType!]!,
    returnValue: BankAccount,
    initialValue: BankAccount,
  }

  type EmploymentContractDraftDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    editing: Boolean!
    requiredUserId: UserId!
    requiredEmploymentContractType: EmploymentContractType!
    errors: [String!]!
  }

  type AddPersonDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    newUserId: UserId
  }

  type SetRateAndProgressionDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredUserId: UserId!
    requiredContractId: ContractId!
    effectiveDate: Date
  }

  type B2BPaymentDetailsDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    editing: Boolean!
    requiredUserId: UserId!
    contractId: ContractId
    effectiveDate: Date
    highlight: TimeBasedHighlight
  }

  type EmploymentPaymentDetailsDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    editing: Boolean!
    requiredUserId: UserId!
    contractId: ContractId
    effectiveDate: Date
    requiredEmploymentContractType: EmploymentContractType!
    highlight: TimeBasedHighlight
  }

  type BonusDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    selectedBonusId: BonusId
    requiredContractId: ContractId!
  }

  type BonusPreviewDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredSelectedBonusId: BonusId!
    requiredContractId: ContractId!
    requiredBonusType: BonusType!
  }

  type AllBonusesDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredContractId: ContractId!
  }

  type EmploymentBankAccountDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    editing: Boolean!
    requiredPromiseeId: UserId!
    contractId: ContractId
    highlight: TimeBasedHighlight
  }

  type B2BContractNoteDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    editing: Boolean!
    requiredPromiseeId: UserId!
    contractId: ContractId
  }

  type EmploymentContractNoteDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    editing: Boolean!
    requiredPromiseeId: UserId!
    contractId: ContractId
  }

  type SubcontractNoteDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    editing: Boolean!
    requiredPromiseeId: UserId!
    contractId: ContractId
  }

  type AddProjectDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    projectId: ProjectId
  }

  type ProjectMembersDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredProjectId: ProjectId!
    clientContractLink: String
  }

  type UserProjectsDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredUserId: UserId!
  }

  type EditProjectDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredProjectId: ProjectId!
  }

  enum EditProfileDetailsDrawer_Tabs {
    BASIC_INFO
    ADDRESS
    ADDITIONAL_DATA
  }

  type EditProfileDetailsDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredUserId: UserId!
    activeProfileTab: EditProfileDetailsDrawer_Tabs
  }

  enum CompanyDetailsDrawer_Tabs {
    REGISTER_INFO
    VAT_TAX
    BANK_ACCOUNTS
  }

  type CompanyDetailsDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredCompanyId: CompanyId!
    activeTab: CompanyDetailsDrawer_Tabs
  }


  type ContractDetailsDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredContractId: ContractId!
  }

  type CompanyTypeDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredCompanyId: CompanyId!
  }

  type CompanyTypeDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredCompanyId: CompanyId!
  }

  type AddSpaceDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type AssignBenefitDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredGroupType: BenefitGroupType!
    requiredContractId: ContractId!
    highlight: TimeBasedHighlight
    effectiveDate: Date
  }

  type EditSpaceDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredSpaceId: SpaceId!
  }

  type EmploymentSalaryDetailsDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredContractId: ContractId!
    requiredMonth: YearMonth!
  }

  type B2BSalaryDetailsDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredContractId: ContractId!
    requiredMonth: YearMonth!
  }

  type AddInternalProjectDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    projectId: ProjectId
  }

  type ContractBankAccountDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredPromiseeId: UserId!
    requiredIsPromisor: Boolean!
    contractId: ContractId
  }

  type BenefitNoteDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    editing: Boolean!
    requiredAssignedVariantId: AssignedVariantId!
    requiredContractId: ContractId!
  }

  type ProjectBillablesReportDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type B2BSalariesSummaryReportDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type BenefitInvoicesReportDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type ClientInvoicingReportDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type CreateBenefitDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredGroupType: BenefitGroupType!
    requiredAlternativeBenefitsGroupId: AlternativeBenefitsGroupId!
    newBenefitId: BenefitId
  }

  type CreateSubBenefitDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredParentId: BenefitId!
    requiredParentEntityVersion: EntityVersion!
    requiredAlternativeBenefitsGroupId: AlternativeBenefitsGroupId!
  }

  type CreateVariantDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredParentId: BenefitId!
  }

  type BusinessTripsReportDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type BasicFinancialReportDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type UopHolidaysReportDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type TimeReportsReportDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type PeopleListReportDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type EditBenefitDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredBenefitId: BenefitId!
    requiredIsBenefit: Boolean!
  }

  type EditBenefitVariantDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredVariantId: BenefitId!
    effectiveDate: Date
    highlight: TimeBasedHighlight
  }

  type EditBenefitVariantPriceDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredVariantId: BenefitId!
    requiredMonth: YearMonth!
    highlight: TimeBasedHighlight
  }

  type InvoicesDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  type DirectReportingDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredUserId: UserId!
    effectiveDate: Date
    highlight: TimeBasedHighlight
  }

  type AnnualLeaveSummaryDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredUserId: UserId!
    requiredContractId: ContractId!
    year: Date!
  }

  type AnnualLeaveEntitlementDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredUserId: UserId!
    requiredContractId: ContractId!
    year: Date!
  }

  type SetFutureEntitlementDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredUserId: UserId!
    requiredContractId: ContractId!
    initialAbsence: Boolean!
    initialDate: Date
  }

  type AnnualLeavePreferencesDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
    requiredUserId: UserId!
    requiredContractId: ContractId!
  }

  type CostSummariesDrawer implements AbstractDrawer {
    id: ID!
    child: AbstractDrawer
    visible: Boolean!
  }

  extend type Query {
    drawer(id: ID!): AbstractDrawer
    rootDrawer: AbstractDrawer
    topDrawer: AbstractDrawer
  }
`);
