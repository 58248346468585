/* eslint-disable */
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
// use `yarn generate:graphql to regenerate


import * as policies from './scalars';

export const scalarTypePolicies = {
  AbsenceRecord: { fields: { from: policies.localDateTypePolicy, to: policies.localDateTypePolicy, } },
	AbsenceRecordEntry: { fields: { appliedAt: policies.dateTypePolicy, pinnedAt: policies.dateTypePolicy, } },
	AccessGranted__LocalDate: { fields: { value: policies.localDateTypePolicy, } },
	AccessGranted__OptionalLocalDate: { fields: { value: policies.localDateTypePolicy, } },
	AccessGranted__OptionalOffsetDateTime: { fields: { value: policies.dateTypePolicy, } },
	AccountingData: { fields: { maternityLeaveAllowance: policies.centsTypePolicy, overtimePayout: policies.centsTypePolicy, ppkAmount: policies.centsTypePolicy, taxPrepayment: policies.centsTypePolicy, taxRefund: policies.centsTypePolicy, zusContribution: policies.centsTypePolicy, } },
	Admin: { fields: { createdAt: policies.dateTypePolicy, } },
	AnnualLeaveEntitlementDrawer: { fields: { year: policies.dateTypePolicy, } },
	AnnualLeaveEntitlementPeriod: { fields: { createdAt: policies.dateTypePolicy, } },
	AnnualLeaveSummaryDrawer: { fields: { year: policies.dateTypePolicy, } },
	ApplyPropertyActivity: { fields: { scheduledAt: policies.dateTypePolicy, } },
	ApplyPropertyListActivity: { fields: { scheduledAt: policies.dateTypePolicy, } },
	AppreciationBonus: { fields: { applyAt: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, paidOutAt: policies.localDateTypePolicy, } },
	AssignBenefitDrawer: { fields: { effectiveDate: policies.dateTypePolicy, } },
	AssignedVariant: { fields: { at: policies.dateTypePolicy, } },
	B2BContract: { fields: { at: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, dataInSystemSince: policies.localDateTypePolicy, expectedEndDate: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, terminationDate: policies.localDateTypePolicy, } },
	B2BContractDraft: { fields: { conclusionDate: policies.localDateTypePolicy, createdAt: policies.dateTypePolicy, expectedEndDate: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, } },
	B2BContractSalaryDetails: { fields: { transferAmount: policies.centsTypePolicy, transferDate: policies.dateTypePolicy, yearMonth: policies.dateTypePolicy, } },
	B2BPaymentDetailsDrawer: { fields: { effectiveDate: policies.dateTypePolicy, } },
	B2BSalaryDetailsDrawer: { fields: { requiredMonth: policies.dateTypePolicy, } },
	Benefit: { fields: { endDate: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, } },
	BenefitVariant: { fields: { at: policies.dateTypePolicy, endDate: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, } },
	BenefitVariantAuditEntry: { fields: { appliedAt: policies.dateTypePolicy, pinnedAt: policies.dateTypePolicy, } },
	CalendarDay: { fields: { date: policies.localDateTypePolicy, } },
	CancelPropertyActivity: { fields: { activityApplyAt: policies.dateTypePolicy, } },
	CancelPropertyListActivity: { fields: { activityApplyAt: policies.dateTypePolicy, } },
	CentsBalance: { fields: { available: policies.centsTypePolicy, entitlement: policies.centsTypePolicy, used: policies.centsTypePolicy, } },
	CentsWithCurrency: { fields: { amount: policies.centsTypePolicy, } },
	ClearableBasisPoint: { fields: { value: policies.basisPointTypePolicy, } },
	ClearableCents: { fields: { value: policies.centsTypePolicy, } },
	ClientContract: { fields: { at: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	ClientContractAuditEntry: { fields: { appliedAt: policies.dateTypePolicy, pinnedAt: policies.dateTypePolicy, } },
	ClientPosition: { fields: { at: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	ClosedEndLocalDateRange: { fields: { from: policies.localDateTypePolicy, to: policies.localDateTypePolicy, } },
	ClosedEndMonthRange: { fields: { from: policies.dateTypePolicy, to: policies.dateTypePolicy, } },
	Company: { fields: { at: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	CompanyAuditEntry: { fields: { appliedAt: policies.dateTypePolicy, pinnedAt: policies.dateTypePolicy, } },
	ContractAuditEntry: { fields: { appliedAt: policies.dateTypePolicy, pinnedAt: policies.dateTypePolicy, } },
	ContractSalaryDetailsAdditionalData: { fields: { yearMonth: policies.dateTypePolicy, } },
	ContractSalaryDetailsId: { fields: { yearMonth: policies.dateTypePolicy, } },
	DirectReportingDrawer: { fields: { effectiveDate: policies.dateTypePolicy, } },
	Done: { fields: { at: policies.dateTypePolicy, } },
	EditBenefitVariantDrawer: { fields: { effectiveDate: policies.dateTypePolicy, } },
	EditBenefitVariantPriceDrawer: { fields: { requiredMonth: policies.dateTypePolicy, } },
	EmployeeReferralBonus: { fields: { applyAt: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, paidOutAt: policies.localDateTypePolicy, } },
	EmploymentContract: { fields: { at: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, dataInSystemSince: policies.localDateTypePolicy, expectedEndDate: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, terminationDate: policies.localDateTypePolicy, } },
	EmploymentContractDraft: { fields: { conclusionDate: policies.localDateTypePolicy, copyrightCosts: policies.basisPointTypePolicy, createdAt: policies.dateTypePolicy, expectedEndDate: policies.localDateTypePolicy, ppk: policies.basisPointTypePolicy, startDate: policies.localDateTypePolicy, } },
	EmploymentContractSalaryDetails: { fields: { transferAmount: policies.centsTypePolicy, transferDate: policies.dateTypePolicy, yearMonth: policies.dateTypePolicy, } },
	EmploymentPaymentDetailsDrawer: { fields: { effectiveDate: policies.dateTypePolicy, } },
	EmploymentSalaryDetailsDrawer: { fields: { requiredMonth: policies.dateTypePolicy, } },
	IncomeDetails: { fields: { from: policies.localDateTypePolicy, to: policies.localDateTypePolicy, vat: policies.basisPointTypePolicy, } },
	IncompleteEntries: { fields: { days: policies.arrayTypePolicy(policies.localDateTypePolicy), } },
	LanguageLearningBonus: { fields: { applyAt: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, paidOutAt: policies.localDateTypePolicy, } },
	LocalDateRange: { fields: { from: policies.localDateTypePolicy, to: policies.localDateTypePolicy, } },
	MissingEntries: { fields: { days: policies.arrayTypePolicy(policies.localDateTypePolicy), } },
	NetGross: { fields: { gross: policies.centsTypePolicy, net: policies.centsTypePolicy, } },
	NetOnCallRate: { fields: { holiday: policies.centsTypePolicy, night: policies.centsTypePolicy, weekend: policies.centsTypePolicy, } },
	OnCallLog: { fields: { dates: policies.arrayTypePolicy(policies.localDateTypePolicy), } },
	OnCallRecord: { fields: { date: policies.localDateTypePolicy, } },
	OnCallRecordEntry: { fields: { appliedAt: policies.dateTypePolicy, pinnedAt: policies.dateTypePolicy, } },
	OtherBonus: { fields: { applyAt: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, paidOutAt: policies.localDateTypePolicy, } },
	Pending: { fields: { at: policies.dateTypePolicy, } },
	Project: { fields: { archivedFrom: policies.localDateTypePolicy, createdAt: policies.dateTypePolicy, startDate: policies.localDateTypePolicy, } },
	ProjectAuditEntry: { fields: { appliedAt: policies.dateTypePolicy, pinnedAt: policies.dateTypePolicy, } },
	Query: { fields: { remoteWorkReimbursementRate: policies.centsTypePolicy, } },
	RateCardDrawer: { fields: { effectiveDate: policies.dateTypePolicy, } },
	Ready: { fields: { at: policies.dateTypePolicy, } },
	RemoteWorkEquipmentBonus: { fields: { applyAt: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, paidOutAt: policies.localDateTypePolicy, } },
	RemoteWorkReimbursement: { fields: { rate: policies.centsTypePolicy, } },
	ReportingPeriod: { fields: { from: policies.localDateTypePolicy, to: policies.localDateTypePolicy, } },
	SchedulePropertyActivity: { fields: { applyAt: policies.dateTypePolicy, } },
	SchedulePropertyListActivity: { fields: { applyAt: policies.dateTypePolicy, } },
	SetFutureEntitlementDrawer: { fields: { initialDate: policies.dateTypePolicy, } },
	SetRateAndProgressionDrawer: { fields: { effectiveDate: policies.dateTypePolicy, } },
	SpaceAuditEntry: { fields: { appliedAt: policies.dateTypePolicy, pinnedAt: policies.dateTypePolicy, } },
	SubBenefit: { fields: { endDate: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, } },
	Subcontract: { fields: { at: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, dataInSystemSince: policies.localDateTypePolicy, expectedEndDate: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, terminationDate: policies.localDateTypePolicy, } },
	SubcontractDraft: { fields: { createdAt: policies.dateTypePolicy, expectedEndDate: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, } },
	TimeBalance: { fields: { at: policies.localDateTypePolicy, } },
	TimeBasedActivityOverlapError: { fields: { date: policies.dateTypePolicy, } },
	TimeBasedCents: { fields: { value: policies.centsTypePolicy, } },
	TimeBasedEffectiveFromInPastError: { fields: { activityDate: policies.dateTypePolicy, now: policies.dateTypePolicy, } },
	TimeBasedHighlight: { fields: { effectiveDate: policies.dateTypePolicy, } },
	TimeBasedInstantBenefitBuyerType: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantBenefitVariant: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantBoolean: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantCents: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, value: policies.centsTypePolicy, } },
	TimeBasedInstantClearableBasisPoint: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableBenefitVariantRate: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableCents: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableDeductibleCostsType: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableNetGross: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableNetOnCallRate: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableOnCallRate: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableProgressionLevel: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableProjectId: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableString: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableUser: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantCompany: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantCompanyRole: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantCurrency: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantRate: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantSpace: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantString: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantValueWithDescriptionAndVerificationBooleanVATStatus: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantValueWithVerificationEuVatType: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantVatType: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantWorkingTime: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedOptClearableBasisPoint: { fields: { value: policies.basisPointTypePolicy, } },
	TimeBasedOptClearableCents: { fields: { value: policies.centsTypePolicy, } },
	TrainingPackageBonus: { fields: { applyAt: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, paidOutAt: policies.localDateTypePolicy, } },
	TransfersSummaries: { fields: { yearMonth: policies.dateTypePolicy, } },
	User: { fields: { at: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	UserAuditEntry: { fields: { appliedAt: policies.dateTypePolicy, pinnedAt: policies.dateTypePolicy, } },
	VatApplied: { fields: { value: policies.basisPointTypePolicy, } },
	VerifiedBankAccountStatus: { fields: { when: policies.dateTypePolicy, } },
	VerifiedEuVatType: { fields: { when: policies.dateTypePolicy, } },
	VerifiedWithDescriptionBooleanVATStatus: { fields: { when: policies.dateTypePolicy, } },
	WorkRecord: { fields: { date: policies.localDateTypePolicy, } },
	WorkRecordEntry: { fields: { appliedAt: policies.dateTypePolicy, pinnedAt: policies.dateTypePolicy, } },
	WorkTime: { fields: { timeFrom: policies.localDateTypePolicy, timeTo: policies.localDateTypePolicy, } },
};